// @auth: RGill and SAlam-Naylor
// @desc: JS for the responsive LiveChat component.

define(['app'], function(app) {



  var liveChat = function() {
    var component = {};

    var _config = {
      checkLiveChatInterval: 0,
      checkCSMaskInterval: 3000,
      checkForLiveChat: true,
      selectors: {
        LPMcontainer: '.LPMcontainer',
        lpChat: '#lpChat',
        lpDesktop: '.lp_desktop',
        lpMobile: '.lp_mobile',
        lpMainArea: '.lp_main_area',
        lpSurveyArea: '.lp_survey_area',
        lpPagingWrapper: '.lp_paging_wrapper',
        lpLocationCenter: '.lp_location_center',
        lpcWindow: '.lpc_window',
        lpcDesktop: '.lpc_desktop',
        lpcMobile: '.lpc_mobile',
        container: '.liveChat',
        lpPanel: '.lp-panel',
        operatorsText: '[data-js-element=statusText]',
        openingTimesOnline: '[data-js-element=openingTimesOnline]',
        openingTimesOffline: '[data-js-element=openingTimesOffline]',
        statusIcon: '[data-js-element=statusIcon]',
        button: '[data-js-element=button]'
      },
      data: {
        stateOnlineText: app.utils.getProperty('stateOnlineText', 'liveChat'),
        stateOfflineText: app.utils.getProperty('stateOfflineText', 'liveChat'),
        onlineText: app.utils.getProperty('onlineText', 'liveChat'),
        offlineText: app.utils.getProperty('offlineText', 'liveChat'),
        statusIconOnlineClassName: 'liveChat_statusIcon-online',
        statusIconOfflineClassName: 'liveChat_statusIcon-offline',
        showComponentClassName: 'liveChat-show'
      },
      attrib: {
        dataCSMask: 'data-cs-mask'
      }
    };

    var _init = function(element) {
      component.element = element;
      component.statusText = document.querySelectorAll(component.config.selectors.operatorsText);
      component.openingTimesOnline = document.querySelector(component.config.selectors.openingTimesOnline);
      component.openingTimesOffline = document.querySelector(component.config.selectors.openingTimesOffline);
      component.button = document.querySelectorAll(component.config.selectors.button);
      component.statusIcon = document.querySelectorAll(component.config.selectors.statusIcon);
      component.container = document.querySelectorAll(component.config.selectors.container);
      component.isLiveChatAvailable();
      component.toggleLiveText(false);
      return component;
    };

    var _isLiveChatAvailable = function() {
      if (component.config.checkForLiveChat) {
        setTimeout(function() {
          if (document.querySelector(component.config.selectors.LPMcontainer)) {
            component.chatIsOnline();
            component.config.checkForLiveChat = false;
          } else {
            component.isLiveChatAvailable();
          }
        }, component.config.checkLiveChatInterval);
      }
    };

    var _toggleLiveText = function (isOnline) {
      if (isOnline || component.openingTimesOffline.textContent.length < 1) {
        component.openingTimesOffline.classList.add('hidden');
        component.openingTimesOnline.classList.remove('hidden');
      } else {
        component.openingTimesOffline.classList.remove('hidden');
        component.openingTimesOnline.classList.add('hidden');
      }
    }

    var _chatIsOnline = function() {
      component.showComponent();
      component.showOnlineIcon();
      Array.from(component.statusText).map(el => el.innerHTML = component.config.data.stateOnlineText + ' ' + component.config.data.onlineText);
      Array.from(component.button).map(el => app.element.removeAttribute('disabled', el));
      component.toggleLiveText(true);
      component.liveChatBtnEventHandler();
    };

    var _chatIsOffline = function() {
      component.hideComponent();
      component.showOfflineIcon();
      Array.from(component.statusText).map(el => el.innerHTML = component.config.data.stateOfflineText + ' ' + component.config.data.offlineText);
      Array.from(component.button).map(el => app.element.setAttribute('disabled', 'disabled', el));
      component.toggleLiveText(false);
    };

    var _liveChatBtnEventHandler = function() {
      component.LPMcontainer = document.querySelector(component.config.selectors.LPMcontainer);

      Array.from(component.button).map(el => el.addEventListener('click', function() {
        component.LPMcontainer.childNodes[0].click();
        component.setCSMask();
      }));
    };

    var _hideComponent = function() {
      app.element.removeClass(component.config.data.showComponentClassName, component.element);
    };

    var _showComponent = function() {
      app.element.addClass(component.config.data.showComponentClassName, component.element);
      component.setCSMask();
    };

    var _setCSMask = function() {
      setTimeout(function() {
        component.lpDesktop = document.querySelector(component.config.selectors.lpDesktop);
        component.lpMobile = document.querySelector(component.config.selectors.lpMobile);

        if (component.lpDesktop == null && component.lpMobile == null) {
          return;
        }

        component.lpChat = document.querySelector(component.config.selectors.lpChat);
        component.lpMainArea = document.querySelector(component.config.selectors.lpMainArea);
        component.lpSurveyArea = document.querySelector(component.config.selectors.lpSurveyArea);
        component.lpPagingWrapper = document.querySelector(component.config.selectors.lpPagingWrapper);
        component.lpLocationCenter = document.querySelector(component.config.selectors.lpLocationCenter);

        component.lpcDesktop = document.querySelectorAll(component.config.selectors.lpcDesktop);
        component.lpcMobile = document.querySelectorAll(component.config.selectors.lpcMobile);

        component.lpcWindow = document.querySelectorAll(component.config.selectors.lpcWindow);

        if (component.lpDesktop == null) {
          component.lpMobile.setAttribute(component.config.attrib.dataCSMask, '');
          Array.from(component.lpcMobile).map(el => el.setAttribute(component.config.attrib.dataCSMask, ''));
        } else {
          component.lpDesktop.setAttribute(component.config.attrib.dataCSMask, '');
          Array.from(component.lpcDesktop).map(el => el.setAttribute(component.config.attrib.dataCSMask, ''));
        }

        if (component.lpMainArea) {
          component.lpMainArea.setAttribute(component.config.attrib.dataCSMask, '');
        }
        if (component.lpSurveyArea) {
        component.lpSurveyArea.setAttribute(component.config.attrib.dataCSMask, '');
      }
        if (component.lpPagingWrapper) {
        component.lpPagingWrapper.setAttribute(component.config.attrib.dataCSMask, '');
      }

        if (component.lpLocationCenter) {
        component.lpLocationCenter.setAttribute(component.config.attrib.dataCSMask, '');
      }

      if (component.lpLocationCenter) {
        Array.from(component.lpcWindow).map(el => el.setAttribute(component.config.attrib.dataCSMask, ''));
      }

      }, (component.config.checkCSMaskInterval));

    };

    var _showOnlineIcon = function() {
      Array.from(component.statusIcon).map(el => app.element.addClass(component.config.data.statusIconOnlineClassName, el));
      Array.from(component.statusIcon).map(el => app.element.removeClass(component.config.data.statusIconOfflineClassName, el));
    };

    var _showOfflineIcon = function() {
      Array.from(component.statusIcon).map(el => app.element.removeClass(component.config.data.statusIconOnlineClassName, el));
      Array.from(component.statusIcon).map(el => app.element.addClass(component.config.data.statusIconOfflineClassName, el));
    };

    component.config = _config;
    component.init = _init;
    component.liveChatBtnEventHandler = _liveChatBtnEventHandler;
    component.toggleLiveText = _toggleLiveText;
    component.chatIsOffline = _chatIsOffline;
    component.chatIsOnline = _chatIsOnline;
    component.isLiveChatAvailable = _isLiveChatAvailable;
    component.hideComponent = _hideComponent;
    component.showComponent = _showComponent;
    component.showOnlineIcon = _showOnlineIcon;
    component.showOfflineIcon = _showOfflineIcon;
    component.setCSMask = _setCSMask;
    return component;
  };

  return liveChat;
});
